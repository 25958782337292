<template>
  <div class="d-flex flex-wrap position-relative justify-content-between align-items-center container w-100">
    <Chip v-for="(chip, index) in casinoChips" :chip="chip" :key="index"
      :class="{ 'chip-active': amount === chip.value }" @clicked="c => handleSelectionChip(c.number)" />
  </div>
</template>
<script>
import Chip from '../../Chip.vue'

export default {
  props: ["amount", "casinoChips"],
  components: { Chip },
  methods: {
    handleSelectionChip(number) {
      this.$emit("updateBalanceBet", number)
      this.$emit("changeChip", number)

    }
  }
}
</script>

<style scoped>
.fish {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.569),
    0 0 5px 1px rgba(90, 90, 90, 0.66),
    inset 0 35px 1px rgba(255, 255, 255, 0.334);
  -moz-box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.576),
    0 0 5px 1px rgba(90, 90, 90, 0.392),
    inset 0 35px 1px rgba(255, 255, 255, 0.397);
  -webkit-box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.1),
    0 0 5px 1px rgba(90, 90, 90, 0.595),
    inset 0 35px 1px rgba(255, 255, 255, 0.2);
  border-width: 5px;
  border-style: dashed;
  cursor: pointer;
  color: black;
  margin: 10px 0;
}

.chip-active {
  box-shadow: 0 0 6px 3px rgb(191, 191, 28);
  transform: scale(1.2);
}

.fish span {
  font-family: Verdana;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  font-weight: bolder;
}
</style>